@media only screen and (max-width: 640px) {
  body {
    padding-top: 67.7833px;
    overflow-x: hidden;
    padding-left: 0;
    padding-right: 0;
  }

  .b-galleryPage .b-spacer {
    margin-top: 0 !important;
  }

  .flex {
    flex-direction: column;
  }

  .b-header {
    width: 100%;
    padding: 0;
    padding-bottom: 15px;
  }
  .b-header--menu {
    width: 90%;
    flex-direction: row !important;
  }

  .b-socialMedia {
    margin: auto;
    padding: 10px;
  }

  .b-galleryPage--header {
    font-size: 1rem;
  }

  .b-threeImages {
    width: 100vw;
    height: auto !important;
  }

  .b-threeImages--smallImage.up {
    margin-bottom: 0 !important;
  }

  .b-threeImages--image {
    min-height: 50vh;
    width: 95vw;
    margin: auto;
    margin-top: 2%;
  }

  .b-threeImages--smallerOnes {
    width: 95vw;
    margin: auto;
  }

  .b-threeImages--smallerOnes.left {
    padding-left: 0 !important;
  }
  .b-threeImages--smallerOnes.right {
    padding-right: 0 !important;
  }

  .b-contactForm {
    width: calc(100vw - 30px);
    margin: auto;
  }

  .b-textWithHeader {
    width: 90%;
  }
  .b-textWithHeader--header {
    width: 90%;
  }
  .b-textWithHeader--text {
    width: 90%;
  }

  .b-hero {
    height: 50vh;
    margin-top: 4%;
    /* background-size: contain !important; */
  }
  .b-hero.flex {
    flex-direction: row;
  }
  .b-hero--text h1 {
    font-size: 3rem;
  }

  #hero-home-main {
    width: 100vw;
    height: 100vw;
    background: url("https://solaairpoland.com/home_mobile.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }

  #hero-home-bottom {
    width: 100vw;
    height: 100vw;
    background: url("https://solaairpoland.com/hero_bottom_mobile.jpeg");
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }

  .b-dropmenu ul {
    top: 25px;
  }

  .modal-body {
    flex-direction: column !important;
  }

  .color-modal__video,
  .color-modal__video video {
    width: 100%;
    height: auto !important;
  }

  .color-modal__video {
    min-height: auto !important;
  }

  .color-modal__video:after {
    display: none;
  }

  .panel-body {
    paddin-left: 10px;
    padding-right: 10px;
  }

  #colors-palette .panel-heading {
    padding: 0 !important;
    margin: 0 !important;
  }

  .panel-heading .panel-title {
    text-align: center;
    margin-top: 20px;
  }

  .panel-heading .panel-title button {
    padding: 0;
  }

  #colors-palette .panel-collapse {
    margin: 0 !important;
  }

  .colors-container {
    padding: 0 15px;
  }

  #adres-biura,
  #adres-korespondencji {
    height: 2rem;
    line-height: 1.15rem;
  }

  button[aria-label="Close"] {
    padding: 5px;
    z-index: 999;
    background: #ebebeb;
  }

  .color-modal__video:after {
    background-position-y: 15px !important;
  }
}
