@font-face {
  font-family: Freight Big Book;
  src: url("./assets/fonts/Freight.woff") format("opentype");
}

@font-face {
  font-family: MOD20;
  src: url("./assets/fonts/MOD20.TTF") format("opentype");
}

@font-face {
  font-family: Amolina;
  src: url("./assets/fonts/Amolina.woff") format("opentype");
}

::selection {
  background-color: black;
  color: white;
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding-left: 2.5%;
  padding-right: 2.5%;
  padding-top: 62.2px;
  font-family: Freight Big Book, serif;
  font-weight: 300;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.parallax {
  /* Full height */

  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
}

a {
  text-decoration: none;
  color: black;
  transition: all 0.3s;
}
a:hover {
  color: #a55970;
}

.text-center {
  text-align: center;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

p {
  letter-spacing: 0.05em;
}

.flex {
  display: flex;
}

.b-link {
  cursor: pointer;
  transition: color 0.3s;
}
.b-link:hover {
  color: #a55970;
}

.b-button {
  display: inline-block;
  min-width: 150px;
  text-align: center;
  padding: 10px;
  font-size: 0.9rem;
  transition: all 0.7s;
  cursor: pointer;
}
.b-hashbutton {
  color: black !important;
}
.b-buttonContainer--center {
  text-align: center;
}
.b-buttonContainer--left {
  text-align: left;
}
.b-buttonContainer--right {
  text-align: right;
}
.b-button--outlined {
  border: solid 1px rgba(128, 128, 128, 1);
}
.b-button--outlined:hover {
  border-color: transparent;
}

.b-header {
  /* max-width: 1200px; */
  margin: auto;
  padding: 20px;
  position: fixed;
  z-index: 999;
  top: 0;
  width: calc(100% - 40px - 5%);
  background-color: white;
}
.b-header--container {
  width: 100%;
  max-width: 1200px;
  margin: auto;
}
.b-header--activeLink {
  color: #a55970 !important;
  /* font-weight: bold; */
  /* text-decoration: underline; */
}
.b-header--menu {
  width: 50%;
  margin: auto;
  align-items: center;
  justify-content: space-evenly;
}
.b-header--menu span {
  display: inline-block;
}
.b-header--language {
  cursor: pointer;
  transition: all 0.3s;
}
.b-header--language:hover {
  color: #a55970;
}

.b-socialMedia {
}
.b-socialMedia--media {
  width: 16px;
  height: 16px;
  display: inline-block;
  margin-right: 11px;
}

.b-footer {
  font-size: 0.8rem;
  letter-spacing: 0.05em;
  width: 75%;
  margin: auto;
  margin-bottom: 2.5%;
  margin-top: 2.5%;
}

.b-hero {
  height: 89vh;
  width: 100%;
  margin: auto;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  justify-items: center;
  align-items: center;
  background-color: #d4d4d4;
}
#hero-home-main {
  background-color: transparent !important;
}

.b-hero--text {
  width: 100%;
  position: relative;
  top: 15px;
}
.b-hero--text h1 {
  text-align: center;
  font-size: 5.5rem;
  font-weight: 300;
  font-family: Amolina, serif;
  color: white;
  /* text-shadow: 5px 5px 0 black; */
}

.b-textWithHeader {
  width: 85%;
  position: relative;
  margin: auto;
  justify-content: center;
  align-items: center;
  color: black;
  /* justify-content */
}
.b-textWithHeader--header {
  width: 30%;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  font-size: 2.7rem;
  /* text-align: right; */
}
.b-textWithHeader--text {
  width: 60%;
  position: relative;
  top: 25px;
}

.b-contactForm {
  width: 60%;
  margin: auto;
}
.b-contactForm--textContainer {
}
.b-contactForm--header {
  font-size: 2.7rem;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  margin-bottom: 1rem;
}
.b-contactForm--text {
  width: 80%;
  margin: auto;
}
.b-contactForm--privacypolicy {
  padding-top: 25px;
  display: inline-block;
  text-align: justify;
  font-size: 14px;
  /* color: #525252; */
}
.b-contactForm--thankyou {
  margin: 0;
  text-align: center;
  height: 40.4667px;
  box-sizing: border-box;
  padding-top: 10px;
}

.b-dropmenu {
  position: relative;
  width: auto;
}
.b-dropmenu ul {
  list-style-type: none;
  position: absolute;
  z-index: 999;
  right: -15px;
  top: 30px;
  width: 150px;
  background-color: white;
  padding-left: 5px;
  padding-right: 5px;
  display: none;
}
.b-dropmenu--item {
  line-height: 27px;
  font-size: 0.9rem;
  text-align: right;
}

.b-textfield {
  width: 100%;
  margin-bottom: 14px;
}
.b-calendarInput {
  position: relative;
}
.b-textfield--labelText,
.b-textarea--labelText {
  font-size: 0.9rem;
  padding-bottom: 2px;
}
.b-textfield--input,
.b-textarea--input {
  width: 100%;
  border: none;
  padding-top: 3px;
  padding-bottom: 3px;
  background-color: rgba(219, 219, 219, 0.7);
  color: black;
  text-overflow: ellipsis;
  border: solid 2px transparent;
  font-size: 14px;
  font-family: Freight Big Book;
  outline: 0;
  box-sizing: border-box;
}
.b-textfield--invalid input,
.b-textarea--invalid textarea {
  background-color: rgba(255, 224, 227, 0.7);
}
.b-textarea--input.non-resizable {
  resize: none;
}

.b-threeImages {
  height: 90vh;
  width: 90%;
  margin: auto;
}
.b-threeImages--image {
  background-color: #d4d4d4;
  background-size: cover;
}
.b-threeImages--bigOne {
  width: 70%;
}
.b-threeImages--smallerOnes {
  width: 30%;
  display: flex;
  flex-direction: column;
}
.b-threeImages--smallerOnes.left {
  padding-left: 2%;
}
.b-threeImages--smallerOnes.right {
  padding-right: 2%;
}
.b-threeImages--smallImage {
  width: 100%;
  height: 100%;
}
.b-threeImages--smallImage.up {
  margin-bottom: 2%;
}
.b-threeImages--smallImage.down {
  margin-top: 2%;
}

.b-galleryPage {
}
.b-galleryPage--top {
  text-align: center;
  margin: auto;
  margin-top: 5%;
}
.b-galleryPage--header {
  text-transform: uppercase;
  font-size: 3.2rem;
  letter-spacing: 0.2em;
}
.b-galleryPage--description p {
  margin: 0;
}
.b-galleryPage--contactButton {
  margin-top: 3rem;
}

.switch-wrapper {
  position: relative;
}

/* REACT CALENDAR */
.react-calendar__tile:disabled {
  background-color: inherit !important;
  color: #d4d4d4 !important;
}
.react-calendar__month-view__days__day--weekend {
  color: inherit !important;
}
.react-calendar__month-view__weekdays__weekday {
  font-family: Freight Big Book !important;
}
.react-calendar__navigation__label {
  font-family: Freight Big Book !important;
  font-size: 1rem;
}
.react-calendar__month-view__weekdays__weekday abbr {
  text-decoration: none;
}

/* Start:/local/templates/updated_adaptive/components/bitrix/news.list/colors.carousel.new/style.css?16599671062726*/
#colors-palette .panel {
  border-radius: 0;
  border: none;
  padding: 0;
  margin: 0;
  overflow: visible;
}
#colors-palette .panel-heading {
  height: 50px;
  padding: 0;
}
#colors-palette .panel-title {
  height: 100%;
}
#colors-palette .panel-title a {
  color: #4d4d4d;
  font-size: 20px;
  font-weight: 300;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 7px 10px;
  position: relative;
}
#colors-palette .panel-title a span {
  display: block;
  position: absolute;
  width: 24px;
  height: 24px;
  right: 40px;
  top: 50%;
  transform: translateY(-50%);
}
#colors-palette .panel-title a span:before {
  content: "";
  display: block;
  position: absolute;
  background: #22b5cc;
  width: 100%;
  height: 1px;
  top: 50%;
  transform: translateY(-50%);
}
#colors-palette .panel-title a span:after {
  content: "";
  display: block;
  position: absolute;
  background: #22b5cc;
  width: 1px;
  height: 100%;
  left: 50%;
  transform: translateX(-50%) rotate(-90deg);
  transition: 0.2s transform ease-out;
}
#colors-palette .panel-title a.collapsed span:after {
  transform: translateX(-50%);
}
#colors-palette .panel-body {
  border: 0;
  color: #000;
  display: grid;
  grid-template-columns: repeat(auto-fill, 208px);
  grid-gap: 40px 20px;
  justify-content: space-between;
  padding: 40px 0;
}
#colors-palette .panel-body:before,
#colors-palette .panel-body:after {
  display: none;
}
.palette-item {
  border: 1px solid #e2e2e2;
  text-align: center;
  cursor: pointer;
}
.palette-item__image {
  height: 100px;
  background-repeat: no-repeat;
  position: relative;
  background-size: cover;
  display: block;
  width: 100%;
  border: none;
  outline: none;
  cursor: pointer;
}
.palette-item__image:before {
  content: "";
  display: block;
  width: 42px;
  height: 42px;
  border: 1px solid #fff;
  border-radius: 50%;
  left: 50%;
  top: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
  background: rgba(0, 0, 0, 0.1);
}
.palette-item__image:after {
  content: "";
  display: block;
  position: absolute;
  border-style: solid;
  border-width: 7.5px 0 7.5px 12px;
  border-color: transparent transparent transparent #fff;
  top: 50%;
  left: 50%;
  transform: translate(-40%, -50%);
}
.palette-item__number {
  padding: 10px 10px 0;
  font-size: 18px;
  line-height: 124.4%;
}
.palette-item__name {
  font-size: 16px;
  line-height: 124.4%;
  font-weight: 300;
  padding: 0 10px 5px;
}
.palette-item__series {
  font-size: 12px;
  line-height: 124.4%;
  padding: 5px 10px;
}
.palette-item__desc {
  display: none;
}
@media (max-width: 480px) {
  #colors-palette .panel-body {
    justify-content: space-between;
    grid-template-columns: repeat(auto-fill, calc(50% - 5px));
  }
}
@media (max-width: 768px) {
  #colors-palette .panel-heading {
    margin: 0 -25px;
    padding: 0 25px;
  }
  #colors-palette .panel-title a {
    font-size: 16px;
    padding-left: 0;
    padding-right: 0;
  }
  #colors-palette .panel-title a span {
    right: 10px;
  }
  #colors-palette .panel-collapse {
    margin: 0 -15px;
  }
  #colors-palette .panel-body {
    justify-content: space-around;
    column-gap: 10px;
  }
  .palette-item__number {
    font-size: 17px;
  }
  .palette-item__name {
    font-size: 15px;
  }
}
#colors-palette .panel-title a span {
  display: none;
}
#colors-palette .panel-collapse {
  display: block;
  height: auto !important;
}
/* End */
/* /local/templates/updated_adaptive/components/bitrix/news.list/colors.carousel.new/style.css?16599671062726 */

.colors-container {
  max-width: 1200px;
  margin: auto;
}

button[data-toggle="collapse"] {
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
  font-size: 2rem;
  font-family: "Freight Big Book";
}

.page-header {
  text-align: center;
}

div[role="dialog"] {
  z-index: 9999;
  max-width: 1220px;
}

div[data-state="open"] {
  z-index: 9998;
}

.modal-body {
  display: flex;
  flex-direction: row !important;
}

.color-modal__video video {
  width: 900px;
}

.color-modal__video:after {
  display: block !important;
}

.color-modal__shapes button.active {
  filter: brightness(0) invert(65%) sepia(46%) saturate(392%) hue-rotate(2deg)
    brightness(92%) contrast(89%);
}

.color-modal__shapes {
  text-align: center;
}

.color-modal__shapes button {
  width: 25px;
  height: 25px;
  margin-right: 24px;
  border: 0;
  position: relative;
  transition: filter 0.25s;
  cursor: pointer;
  display: inline-flex;
}

.color-modal__shapes button.active:after {
  content: "";
  display: block;
  width: 100%;
  left: 0;
  height: 3px;
  background: #00bfd2;
  bottom: -7px;
  position: absolute;
  outline: 0;
}

.color-modal__shape1 {
  background: url(https://solaair.com/images/updated/colors.svg#shape1)
    no-repeat center center / 100% auto;
}

.color-modal__shape2 {
  background: url(https://solaair.com/images/updated/colors.svg#shape2)
    no-repeat center center / 100% auto;
}

.color-modal__shape3 {
  background: url(https://solaair.com/images/updated/colors.svg#shape3)
    no-repeat center center / 100% auto;
}

.color-modal__video {
  position: relative;
  max-width: "900px";
  min-height: "505px";
}

.color-modal__video:after {
  content: "";
  z-index: 1;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  display: block;
  position: absolute;
  background-image: url(https://solaair.com/images/updated/color-video-overlay.svg);
  background-repeat: no-repeat;
  background-size: 100% 90%;
  background-position-y: 30px;
}

.color-modal__title {
  font-size: 20px;
  text-align: center;
}

.color-modal__content {
  padding-left: 20px;
}

.color-modal__content p {
  text-align: center;
}

.color-modal__size {
  font-size: 13px;
  line-height: 124.4%;
  margin-left: 8px;
}

.color-modal__size:before {
  content: "";
  width: 9px;
  height: 9px;
  background: url(https://solaair.com/images/updated/colors.svg#size) no-repeat
    center center / 100% auto;
  display: inline-block;
  margin-right: 3px;
}

div[role="dialog"] {
  overflow: auto;
}

.b-socialMedia--image {
  width: 20px;
  height: 20px;
}
